// src/pages/About.js
import React,{ useState } from 'react';
import BreadCrumb from '../../components/common/BreadCrumb';
// import DesigningServices from '../components/Designing/DesigningServices';
import CtaButton from '../../components/common/CtaButton';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Ghostwritingbg from '../../images/Ghostwritingbg.png'; // Import the image
import FAQSection from '../../components/Designing/DesignFaq';
import ContactForm from '../../components/common/ContactForm1';
import SecondSection from '../../components/home/SecondSection';
import DesignProcess from '../../components/common/DesignProcess';
import ImageRightBanner from '../../components/common/ImageRightText';
import ImageLeftBanner from '../../components/common/ImageLeftText';
//import FictionImage1 from '../../images/fictiongimg1.png';
import BookCoverDesign from '../../images/BookCoverDesign.jpg';
import ghostWritingImage from '../../images/Ghost Writing.png';
import designingImage from '../../images/Designing.png';
import bookEditingImage from '../../images/book edit.png';
import designprocess1 from '../../images/design-process-1.png';
import designprocess2 from '../../images/design-process-2.png';
import designprocess3 from '../../images/design-process-3.png';
import designprocess4 from '../../images/design-process-4.png';
import PopupForm1 from '../../components/common/PopupForm';
import { Helmet } from 'react-helmet-async';

// ******************************** Start SecondSection ******************************
const services = [
  {
    title: "The Power of First Impressions",
    image: ghostWritingImage, // Update this if you have a specific image
    description: "Designing a book cover is the visual gateway to your narrative. It's the first impression that shapes reader perception, establishing your credibility and piquing their curiosity. A compelling cover encourages engagement, leading to exploration of your story and ultimately contributing to your success as an author."
  },
  {
    title: "Building Your Author Brand",
    image: designingImage, // Update this if you have a specific image
    description: "Consistent covers build your author's brand, creating a recognizable visual identity. Striking designs differentiate your work, capturing attention and boosting sales. Our book cover designers craft covers that strengthen your brand and cultivate a loyal readership."
  },
  {
    title: "Driving Sales with Emotional Impact",
    image: bookEditingImage, // Update this if you have a specific image
    description: "Compelling covers evoke the story's emotions, creating an immersive reading experience and increasing reader engagement. Studies show covers significantly influence purchase decisions. Our service ensures your cover not only represents your narrative but also drives sales."
  }
];
// ******************************** End SecondSection ******************************
// ******************************** Start Design Process Props ******************************
const heading = {
  title: (
    <>
  Our Commitment to 
    
    </>
  ),
  highlight: "Excellence"
};
// const description = "At AMZ Book Publishings, our medical ghostwriting services are designed to make your research process easier and more effective.";
const steps = [
  {
    image: designprocess1,
    alt: " Exceptional Results",
    title: " Exceptional Results",
    description: "Our primary objective is to deliver outstanding results to our clients, and our team of skilled writers continually strives to develop the most effective approach."
  },
  {
    image: designprocess2,
    alt: " Efficiency & Timely Delivery",
    title: " Efficiency & Timely Delivery",
    description: "We excel in delivering on time by being efficient, proficient, and ready. Our team ensures meeting all deadlines while consistently staying ahead of schedule."
  },
  {
    image: designprocess3,
    alt: " Guaranteed Satisfaction",
    title: " Guaranteed Satisfaction",
    description: "Achieve your writing dreams by utilizing our book writing services, where we promise to deliver satisfaction with each book created."
  },
  {
    image: designprocess4,
    alt: "Ownership",
    title: "Ownership",
    description: "We believe in empowering our clients. That's why we grant you 100% ownership of the book-writing content, giving you the freedom to pursue your literary goals without any restrictions."
  }
];

// ******************************** End Design Process Props ******************************

// ******************************** Start FAQ Section ******************************
const faqData = [
  {
    question: "What is a book cover design design?",
    answer: "A book cover design design is a series of targeted marketing activities designed to promote a book to a specific audience. The goal is to increase visibility, generate buzz, and ultimately drive sales for the book."
  },
  {
    question: "Is a book cover design design important?",
    answer: "Absolutely! A well-crafted book cover design can create excitement and anticipation around your book, helping it stand out in a crowded market. It engages potential readers and gets them eager to dive into your story, increasing the chances of sales."
  },
  {
    question: "Will my book cover design design have images and music?",
    answer: "Yes, it will! A great book cover design combines stunning visuals and captivating music to create an immersive experience that draws in your audience. This multimedia approach not only enhances engagement but also leaves a lasting impression."
  },
  {
    question: "Can you incorporate my ideas into a book cover design?",
    answer: "Definitely! Your ideas are essential to the process. We will work closely with you to ensure that your vision and unique voice are woven into the teaser, making it a true reflection of your book."
  },
  {
    question: "Will my book cover design be confidential?",
    answer: "Absolutely! We prioritize your privacy and confidentiality. Your ideas and materials will be kept secure throughout the entire process, so you can focus on what matters most: bringing your story to life."
  }
];
// ******************************** End FAQ Section ******************************
const BookTeaser = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      <Helmet>
        <title>Custom Book Cover Design Services | AMZ Book Publishing</title>
        <meta name="description" content="Need a book cover designer? We offer professional book cover design services for self-published authors at affordable prices." />
        <link rel="canonical" href="https://amzbookpublishing.net/professional-book-cover-design-services " />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Custom Book Cover Design Services | AMZ Book Publishing" />
        <meta property="og:description" content="Need a book cover designer? We offer professional book cover design services for self-published authors at affordable prices." />
        <meta property="og:url" content="https://amzbookpublishing.net/professional-book-cover-design-services" />
        <meta property="og:site_name" content="AMZBookPublishing" />
        <meta property="article:publisher" content="https://www.facebook.com/AmzBookPublishingUS" />
        <meta property="article:modified_time" content="2024-08-23T07:06:24+00:00" />


      </Helmet>
      {/* -------------------Start Banner section---------------------- */}
      <Header />
      <BreadCrumb
        title="Book Cover Design Services"
        desc={
          <>
Just as a chef transforms ingredients into a delicious meal, a skilled book cover designer transforms your brilliant ideas into a captivating and marketable book.
          </>
        }
        buttonText="Get a Quote"
        onClick={openModal} // Call openModal on button click
        backgroundImage={Ghostwritingbg} // Pass the image URL as a prop
      />
      {/* Popup Form */}
      <PopupForm1 isOpen={isModalOpen} closeModal={closeModal} />
{/* -------------------End Banner section---------------------- */}
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<ImageTextBanner 
    contentItems={contentItems} 
/>
</div> */}
<div style={{ padding: '30px 0px 100px 0px' }}>
<ImageRightBanner
    heading="Professional Book"
    subHeading=" Cover Design Services"
    description={
        <>
A book cover is not just a visually appealing wrapper, but a gateway for readers to enter the richly detailed world of your book. A professional book cover design service is where artistic and storytelling expertise the heart of your story into a captivating image that grabs attention, sparks interest, and prepares readers for the literary adventure that awaits.

<br/><br/>
As a professional book cover design agency, we view our book cover design services as a collaborative endeavor that transforms your concepts, messages, and personalities into vibrant visual representations. Our experienced design team recognizes the significant impact of a book cover as a key promotional asset, serving as a visual storyteller that encapsulates the essence of your narrative instantly.
        </>
    }
    imageSrc={BookCoverDesign}
    objectfit="contain"
    imageHeight="400px"  // Set height here
    imageWidth="100%"     // Set width here
    borderRadius="30px"
/>
</div>
{/*****************  SERVICE Section *****************/}
<div>
<SecondSection 
        buttonLabel="S e r v i c e s"
        heading1={ <>
       The Impact Of Custom   </>}
        subHeading={<>
          Book    <br/>
          Cover Design </>}
        heading2="Services"
        paragraph="A well-designed book cover is more than just an image; it's a powerful marketing tool with the ability to significantly impact a reader's journey."
        services={services}
      />
      </div>
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<SecondSection/>
</div> */}
<DesignProcess 
        heading={heading}
        // description={description}
        steps={steps}
      />
<div style={{ padding: '0px 0px 50px 0px' }}>
<ImageLeftBanner
    heading="Our Book Cover "
    subHeading="Design Process!"
    description={
      <>
We begin by deeply exploring your book's themes, characters, and mood, collaborating with you to understand your vision and explore concepts that align with your story's essence. Once we have a clear understanding, our creative book cover design service team starts brainstorming visual ideas. Our artists and designers then work their magic to create a visual masterpiece that encapsulates your book's narrative and captures potential readers' attention. 
<br/><br/>
We carefully select fonts that complement the design and reflect the genre and mood of your story, ensuring your title and author name shine. Finally, we add visual elements that speak to your book's themes, skillfully integrating them to create a cover that tells a story.
      </>
  }
    imageSrc={BookCoverDesign}
    imageHeight=""        // Set height here
    imageWidth=""          // Set width here
    objectfit="cover"          // Set object-fit here
    borderRadius="15px"        // Set border radius here
/>
</div>
<CtaButton/>
         
          <div>
      <FAQSection faqData={faqData} />
    </div>
         <ContactForm/>

      <Footer/>
    </div>
  );
};
export default BookTeaser;


