// src/pages/About.js
import React,{ useState } from 'react';
import BreadCrumb from '../components/common/BreadCrumb';
// import DesigningServices from '../components/Designing/DesigningServices';
import CtaButton from '../components/common/CtaButton';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Ghostwritingbg from '../images/Ghostwritingbg.png'; // Import the image
import FAQSection from '../components/Designing/DesignFaq';
import ContactForm from '../components/common/ContactForm1';
import SecondSection from '../components/home/SecondSection';
import DesignProcess from '../components/common/DesignProcess';
import ImageRightBanner from '../components/common/ImageRightText';
import ImageLeftBanner from '../components/common/ImageLeftText';
import FictionImage1 from '../images/Business1.png';
import fictionwhychoose from '../images/fictionwhychoose.png';
import ghostWritingImage from '../images/Ghost Writing.png';
import designingImage from '../images/Designing.png';
import bookEditingImage from '../images/book edit.png';
import designprocess1 from '../images/design-process-1.png';
import designprocess2 from '../images/design-process-2.png';
import designprocess3 from '../images/design-process-3.png';
import designprocess4 from '../images/design-process-4.png';
import PopupForm1 from '../components/common/PopupForm';
import { Helmet } from 'react-helmet-async';

// ******************************** Start SecondSection ******************************

const services = [
  {
    title: "Thought Leadership Articles",
    image: ghostWritingImage,
    description: "At AMZ Book Publishings, our ghostwriters craft thought-provoking articles that position you as an industry leader, helping you share your expertise in a clear and impactful way."
  },
  {
    title: "Corporate Biographies",
    image: designingImage,
    description: "Our business book ghostwriters specialize in turning your professional journey into a captivating story, highlighting your successes and the lessons that shaped your company."
  },
  {
    title: "White Papers & Case Studies",
    image: bookEditingImage,
    description: "We create in-depth white papers and case studies that not only demonstrate your business solutions but also connect with your target audience through compelling narratives and data-backed insights."
  }
];

// ******************************** End SecondSection ******************************

// ******************************** Start Design Process Props ******************************

const heading = {
  title: (
    <>
     Our Business Ghostwriting Process
     <br />
    </>
  ),
  // highlight: "Ghostwriting Masterpiece"
};
// const description = "We follow a proper book cover designing process to ensure the best final product for our clients.";

const steps = [
  {
    image: designprocess1,
    alt: "Initial Consultation & Research",
    title: "Initial Consultation & Research",
    description: "We start with a detailed consultation to understand your business, goals, and target audience. Our business book ghostwriters dive deep into your industry, gathering relevant data and insights to shape the project."
  },
  {
    image: designprocess2,
    alt: "Strategizing & Outlining",
    title: "Strategizing & Outlining",
    description: "We work with you to create a clear and structured outline that lays the foundation for your business book. This step ensures your ideas are organized and aligned with your vision."
  },
  {
    image: designprocess3,
    alt: "Writing & Revisions",
    title: "Writing & Revisions",
    description: "Our business ghostwriting services help you craft the manuscript while regularly consulting with you to capture your voice and vision. Revisions are made along the way to ensure the content is perfect."
  },
  {
    image: designprocess4,
    alt: "Final Edits & Publishing Support",
    title: "Final Edits & Publishing Support",
    description: "After refining the manuscript, we provide professional editing, formatting, and guidance through the publishing process, helping you take your business book to market."
  }
];


// ******************************** End Design Process Props ******************************

// ******************************** Start FAQ Section ******************************
const faqData = [
  {
    question: "How do I know if I need a business ghostwriter?",
    answer: "If you are an expert in your field but do not have the time or writing skills to put your thoughts into a compelling business book, that is where we come in. Our business book ghostwriters for hire are here to help you turn your ideas into professional manuscripts while you stay focused on running your business."
  },
  {
    question: "Will the book still feel like it was written by me?",
    answer: "Absolutely! We take the time to understand your voice, style, and vision through detailed interviews and brainstorming sessions. Our best business ghostwriters ensure that your book not only reflects your expertise but also sounds authentically you."
  },
  {
    question: "What is the process like for business ghostwriting?",
    answer: "It is a collaborative journey. We start with understanding your goals and audience, then move on to outlining, writing, and revising until we have a polished manuscript. You are involved every step of the way to make sure the final product meets your expectations."
  },
  {
    question: "Can you help me get my business book published?",
    answer: "Yes! We do not just write the book; we guide you through the publishing process as well. Whether you are looking for traditional publishing or self-publishing, we provide support with everything from editing to cover design to marketing strategies."
  },
  {
    question: "How long does it take to write a business book?",
    answer: "It depends on the complexity of the project, but typically, it takes a few months to complete a full manuscript. Our business book ghostwriters for hire work with you to establish a timeline that fits your schedule while maintaining quality at every stage."
  }
];


// ******************************** End FAQ Section ******************************

const BusinessGhostwriting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
     <Helmet>
        <title>Business Book Ghostwriting Services by Expert Ghostwriters</title>
        <meta name="description" content="Need a business book ghostwriter? We offer business book ghostwriting services at an affordable price. Meet our talented writers who can work easily." />
        <link rel="canonical" href="https://amzbookpublishing.net/business-book-ghostwriting-services  " />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Business Book Ghostwriting Services by Expert Ghostwriters" />
        <meta property="og:description" content="Need a business book ghostwriter? We offer business book ghostwriting services at an affordable price. Meet our talented writers who can work easily." />
        <meta property="og:url" content="https://amzbookpublishing.net/business-book-ghostwriting-services" />
        <meta property="og:site_name" content="AMZBookPublishing" />
        <meta property="article:publisher" content="https://www.facebook.com/AmzBookPublishingUS" />
        <meta property="article:modified_time" content="2024-08-20T07:39:16+00:00" />
      </Helmet>
      {/* -------------------Start Banner section---------------------- */}
      <Header />
      <BreadCrumb
        title="Business Ghostwriting"
        desc={
          <>
           Unlock your entrepreneurial potential with our business ghostwriting services, where our skilled business ghostwriters bring your ideas to life with professionalism and flair.
          </>
        }
        buttonText="Get a Quote"
        onClick={openModal} // Call openModal on button click
        backgroundImage={Ghostwritingbg} // Pass the image URL as a prop
      />

      {/* Popup Form */}
      <PopupForm1 isOpen={isModalOpen} closeModal={closeModal} />
    
   
{/* -------------------End Banner section---------------------- */}

{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<ImageTextBanner 
  
    
    contentItems={contentItems} 
/>
</div> */}


<div style={{ padding: '40px 0px 100px 0px' }}>
<ImageRightBanner
    heading="Business "
    subHeading="Ghostwriting"
    description={
        <>
         At AMZ Book Publishings, we understand that in the fast-paced world of business, effective communication is key to success. Our business ghostwriting services are designed to help you articulate your ideas, strategies, and insights in a polished and engaging manner. Whether you are looking to publish a book, craft compelling articles, or develop thought leadership content, our experienced business ghostwriters are here to transform your vision into captivating narratives that resonate with your audience 
           <br/><br/>
           What sets us apart is our commitment to understanding your unique voice and objectives. Our team collaborates closely with you, conducting thorough research and discussions to ensure that every piece we produce aligns with your brand and message.
        </>
    }
    imageSrc={FictionImage1}
    objectfit="contain"
    imageHeight="400px"  // Set height here
    imageWidth="100%"     // Set width here
    borderRadius="30px"
/>
</div>

{/*****************  SERVICE Section *****************/}
<div>
<SecondSection 
        buttonLabel="S e r v i c e s"
        heading1={ <>
        Tap Into Expert  </>}
        subHeading={<>
        Business<br/>
        Ghostwriting </>}
        heading2="Services"
        paragraph="Unlock the power of your ideas with our expert business ghostwriting services, designed to elevate your brand and connect with your audience!"
        services={services}
      />
      </div>
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<SecondSection/>
</div> */}
<DesignProcess 
        heading={heading}
        // description={description}
        steps={steps}
      />
<div style={{ padding: '0px 0px 50px 0px' }}>
<ImageLeftBanner
    heading="Why Choose Us"
    subHeading=""
    description={
      <>
       We pride ourselves on being the best business ghostwriters, offering a personalized approach to every project. Our team of business book ghostwriters for hire works closely with you to ensure that your vision is captured perfectly from concept to final manuscript.  

       <br /><br />
       We do not just write; we craft engaging, professional content that reflects your expertise and captivates your target audience. With a commitment to meeting deadlines and providing expert support throughout the entire process, we ensure a smooth, enjoyable, and rewarding experience as we bring your business ideas to life.

      </>

    }
    imageSrc={fictionwhychoose}
    imageHeight=""        // Set height here
    imageWidth=""          // Set width here
    objectfit="cover"          // Set object-fit here
    borderRadius="15px"        // Set border radius here
/>


</div>
<CtaButton/>
         
          <div>
      <FAQSection faqData={faqData} />
    </div>
         <ContactForm/>

      <Footer/>
    </div>
    
  );
};
export default BusinessGhostwriting;


