// LOCAL
//export const API_URL = 'https://blogs.test/api'

export const API_URL = 'https://blogs.redfeathersolutions.org/api'

export const BRAND = 'amz'

// STAGING & LIVE
// export const API_URL = 'https://blogs.redfeathersolutions.org/api'
// export const BRAND = 'content-writing-pulse'
